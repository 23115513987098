// extracted by mini-css-extract-plugin
export var articleWrapper = "m_pr";
export var articleText = "m_ps d_dr d_cp";
export var header = "m_pt d_W d_s";
export var headerImageWrapper = "m_pv d_9 d_X d_5 d_4 d_1 d_6 d_bg d_bN";
export var headerGradient = "m_pw d_9 d_X d_5 d_4 d_1 d_6";
export var headerGradientOverlay = "m_px d_9 d_X d_5 d_4 d_1 d_6";
export var headerContentWrapper = "m_py d_bw d_bL d_bz d_bF d_bh d_W";
export var headerContentWrapperAlt = "m_pz d_bw d_bL d_bz d_bF d_bh d_W";
export var contentWrapper = "m_mC d_s";
export var dateTag = "m_pB d_cb d_cn d_dm d_s";
export var icon1 = "m_pC d_cL";
export var icon2 = "m_pD d_cL d_cF";
export var tagBtn = "m_pF d_bY d_cz d_dl d_dx";
export var headerText = "m_pG d_s d_cb d_cp d_bS";
export var center = "m_pH d_ds";
export var videoIframeStyle = "m_pJ d_d2 d_s d_D d_bv d_bY d_N";
export var articleImageWrapper = "m_pK d_cp d_W";
export var articleImageWrapperIcon = "m_pL d_cp d_W";
export var articleRow = "m_pM d_bz d_bb";
export var quoteWrapper = "m_gN d_by d_bL d_cp";
export var quoteBar = "m_pN d_D";
export var quoteText = "m_pP";
export var authorBox = "m_pQ d_s";
export var authorRow = "m_pR d_bz d_bS d_cv";
export var separator = "m_pS d_s";
export var line = "m_fg d_s d_fg d_cV d_cv";
export var authorImage = "m_lr d_b4 d_W d_s d_cq";
export var authorText = "m_pT d_cd d_cq";
export var masonryImageWrapper = "m_pV";
export var bottomSeparator = "m_pW d_s d_cv";
export var linksWrapper = "m_pX d_cd d_dr";
export var comments = "m_pY d_bz d_cv";
export var sharing = "m_pZ d_bw d_bz d_bF";
export var shareText = "m_p0 d_s d_ds";
export var icon = "m_p1";
export var text = "m_p2";
export var SubtitleSmall = "m_p3";
export var SubtitleNormal = "m_p4";
export var SubtitleLarge = "m_p5";