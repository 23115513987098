// extracted by mini-css-extract-plugin
export var quoteParagraphLeft = "p_qF d_gQ d_cs d_dr";
export var quoteParagraphCenter = "p_gR d_gR d_cs d_ds";
export var quoteParagraphRight = "p_gS d_gS d_cs d_dt";
export var quoteRowLeft = "p_qG d_bC";
export var quoteRowCenter = "p_qH d_bz";
export var quoteRowRight = "p_qJ d_bD";
export var quoteWrapper = "p_gN d_gN d_s d_cz d_bw d_bL d_bz";
export var quoteContentWrapper = "p_gP d_gP";
export var quoteExceptionSmall = "p_qK q_qK";
export var quoteExceptionNormal = "p_qL q_qL";
export var quoteExceptionLarge = "p_qM q_qM";
export var quoteAuthorExceptionSmall = "p_qN q_qN";
export var quoteAuthorExceptionNormal = "p_qP q_qP";
export var quoteAuthorExceptionLarge = "p_qQ q_qQ";